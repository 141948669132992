import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import './app.less';

//page
import asyncComponent from './component/async-component';
const placeholder = <span style={{ position: 'fixed', width: '100%', height: '100%', display: 'flex', alignItems: 'center', 'justifyContent': 'center' }}>loading……</span>

// PC
const PageHome = asyncComponent(() => import('./page/home'), placeholder);
const PageLogin = asyncComponent(() => import('./page/login'), placeholder);
const PagePorts = asyncComponent(() => import('./page/ports'), placeholder);
const PageContainerSize = asyncComponent(() => import('./page/containerSize'), placeholder);
const PageHsCode = asyncComponent(() => import('./page/hscode'), placeholder);
const PageProfile = asyncComponent(() => import('./page/profile'), placeholder);
const PageOceanFreightTracking = asyncComponent(() => import('./page/oceanFreightTracking'), placeholder);
const PageWorkbench = asyncComponent(() => import('./page/workbench'), placeholder);

function App() {
  return (
	  <Router>
		  <div>
        <Route exact path="/" component={PageHome} />
        <Route exact path="/home" component={PageHome} />
        <Route exact path="/login" component={PageLogin} />
        <Route exact path="/ports" component={PagePorts} />
        <Route exact path="/container/size" component={PageContainerSize} />
        <Route exact path="/hscode" component={PageHsCode} />
        <Route exact path="/user/profile" component={PageProfile} />
        <Route exact path="/ocean-freight/tracking" component={PageOceanFreightTracking} />
        <Route exact path="/workbench/:page" component={PageWorkbench} />
		  </div>
	  </Router>
  );
}

export default App;
